<template>
  <aside
    class="app-sidebar"
    :class="computedClass"
    :style="computedStyle"
  >
    <ul class="app-sidebar__menu">
      <template v-for="(item, key) in items">
        <app-sidebar-link-group
          :key="key"
          :minimized="minimized"
          :icon="item.meta && item.meta.iconClass"
          v-if="item.children && isAnyAllowed(item.children) && hasAnyModule(item.children)"
          :title="$t(item.displayName)"
          :children="item.children"
          :active-by-default="hasActiveByDefault(item)"
        >
          <template v-for="(subMenuItem, key) in item.children">
            <app-sidebar-link
              v-if="isAllowed(subMenuItem) && hasModule(subMenuItem)"
              :key="key"
              :icon="subMenuItem.meta && subMenuItem.meta.iconClass"
              :to="{ name: subMenuItem.name }"
              :title="$t(subMenuItem.displayName)"
            />
          </template>
        </app-sidebar-link-group>
        <app-sidebar-link
          v-else-if="isAllowed(item) && hasModule(item)"
          :key="key"
          :minimized="minimized"
          :active-by-default="item.name === $route.name"
          :icon="item.meta && item.meta.iconClass"
          :to="{ name: item.name }"
          :title="$t(item.displayName)"
        />
      </template>
    </ul>
  </aside>
</template>

<script>
import { navigationRoutes } from './NavigationRoutes'
import { ColorThemeMixin } from '../../../services/vuestic-ui'
import { mapGetters } from 'vuex'
const AppSidebarLink = () => import(/* webpackPrefetch: true */ './components/AppSidebarLink')
const AppSidebarLinkGroup = () => import(/* webpackPrefetch: true */ './components/AppSidebarLinkGroup')

export default {
  name: 'app-sidebar',
  inject: ['contextConfig'],
  components: {
    AppSidebarLink,
    AppSidebarLinkGroup,
  },
  mixins: [ColorThemeMixin],
  props: {
    minimized: {
      type: Boolean,
      required: true,
    },
    color: {
      type: String,
      default: 'secondary',
    },
  },
  data () {
    return {
      items: navigationRoutes.routes,
    }
  },
  computed: {
    ...mapGetters(['currentUser']),
    computedClass () {
      return {
        'app-sidebar--minimized': this.minimized,
      }
    },
    computedStyle () {
      return {
        backgroundColor: this.contextConfig.invertedColor ? 'white' : this.colorComputed,
      }
    },
  },
  methods: {
    isAnyAllowed (items) {
      if (!this.currentUser) return false
      const allows = items.map(item => this.isAllowed(item))
      return allows.some(a => a === true)
    },
    isAllowed (item) {
      if (!this.currentUser) return false
      if (item.children) return false
      if (!item.meta || !item.meta.permission) return true

      const permission = item.meta.permission
      return this.currentUser.can(permission.controller, permission.action)
    },
    hasAnyModule (items) {
      if (!this.currentUser) return false
      const allows = items.map(item => this.hasModule(item))
      return allows.some(a => a === true)
    },
    hasModule (item) {
      if (!this.currentUser) return false
      if (item.children) return false
      if (!item.meta || !item.meta.module) return true

      const mod = item.meta.module
      return this.currentUser.hasModule(mod)
    },
    hasActiveByDefault (item) {
      return item.children.some(child => child.name === this.$route.name)
    },
  },
}

</script>

<style lang="scss">
.app-sidebar {
  overflow: auto;
  display: flex;
  max-height: 100%;
  flex: 0 0 16rem;

  @include media-breakpoint-down(sm) {
    flex: 0 0 100%;
  }

  &--minimized {
    flex: 0 0 3.25rem;
  }

  &__menu {
    margin-bottom: 0;
    padding-top: 2.5625rem;
    padding-bottom: 2.5rem;
    list-style: none;
    padding-left: 0;
    width: 100%;
  }
}
</style>
